import { Component, inject, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { User } from '@angular/fire/auth';
import { AxeptioService } from './services/axeptio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {

  #authService: AuthService = inject(AuthService);
  #userService: UserService = inject(UserService);
  #axeptioService: AxeptioService = inject(AxeptioService);

  ngOnInit(): void {
    this.#authService.authStateFromFirebase$()
      .pipe(
        tap((user: User | null) => this.#userService.setUser(user)),
      )
      .subscribe();

    // cookies banner
    this.#axeptioService.loadAxeptio();
  }
}

