import { inject, Injectable } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { from, map, Observable } from 'rxjs';
import { OnCallResponseData } from 'web-business/src/assets/types/Serialization.type';
import { ConfirmationResponse } from 'web-business/src/assets/responses/Confirmation.response';
import {
  GetAvailableBankAccountsOnCallRequest,
} from '../../assets/requests/bankAccount/GetAvailableBankAccountsOnCall.request';
import { BankAccountModel } from '@models/bankAccounts/BankAccount.model';
import { SelectBankAccountOnCallRequest } from '../../assets/requests/bankAccount/SelectBankAccountOnCall.request';
import { BalanceModel } from '@models/balances/Balance.model';
import {
  UpdateBeneficiaryModeOnCallRequest,
} from '../../assets/requests/beneficiaries/UpdateBeneficiaryModeOnCall.request';
import {
  AcceptBeneficiaryCguOnCallRequest,
} from '../../assets/requests/beneficiaries/AcceptBeneficiaryCguOnCall.request';
import {
  GetSyncedBankAccountStatusOnCallRequest,
} from '../../assets/requests/bankAccount/GetSyncedBankAccountStatusOnCall.request';
import {
  GetSyncedBankAccountStatusResponse,
} from '../../assets/responses/BankAccounts/GetSyncedBankAccountStatus.response';
import {
  UpdateSyncedBankAccountsOnCallRequest,
} from '../../assets/requests/bankAccount/UpdateSyncedBankAccountsOnCall.request';
import { SyncedBankAccountUrlResponse } from '../../assets/responses/BankAccounts/SyncedBankAccountUrl.response';
import {
  AddSyncedBankAccountsOnCallRequest,
} from '../../assets/requests/bankAccount/AddSyncedBankAccountsOnCall.request';
import { CreatePinForBeneficiaryOnCallRequest } from '../../assets/requests/auth/CreatePinForBeneficiaryOnCall.request';
import {
  LoginForBeneficiaryWithPinOnCallRequest,
} from '../../assets/requests/auth/LoginForBeneficiaryWithPinOnCall.request';
import { LoginResponse } from '../../assets/responses/Login.response';
import { GetPaybacksMobileOnCallRequest } from '../../assets/requests/paybacks/GetPaybacksMobileOnCallRequest';
import { PaybackModel } from '@models/paybacks/Payback.model';
import { GetFullBeneficiaryOnCallRequest } from '../../assets/requests/users/GetFullBeneficiaryOnCall.request';
import { FullUserModel } from '@models/user/User.model';
import { deserializeTimestampFields } from '../../assets/utils/functions/timestamp.util';
import { GetLatestBalanceRequest } from '../../assets/requests/balances/GetBalance.request';

type BackendClient = {
  'BANK_ACCOUNT-getAvailableBankAccounts_onCall': {
    request: GetAvailableBankAccountsOnCallRequest,
    response: BankAccountModel[],
  },
  'BANK_ACCOUNT-selectBankAccount_onCall': {
    request: SelectBankAccountOnCallRequest,
    response: ConfirmationResponse,
  },
  'BALANCES-getLatestBalance_onCall': {
    request: GetLatestBalanceRequest,
    response: BalanceModel,
  },
  'BENEFICIARIES-updateBeneficiaryMode_onCall': {
    request: UpdateBeneficiaryModeOnCallRequest,
    response: ConfirmationResponse,
  },
  'BENEFICIARIES-acceptBeneficiaryCgu_onCall': {
    request: AcceptBeneficiaryCguOnCallRequest,
    response: ConfirmationResponse,
  },
  'BANK_ACCOUNT-getSyncedBankAccountStatus_onCall': {
    request: GetSyncedBankAccountStatusOnCallRequest,
    response: GetSyncedBankAccountStatusResponse,
  },
  'BANK_ACCOUNT-updateSyncedBankAccounts_onCall': {
    request: UpdateSyncedBankAccountsOnCallRequest,
    response: SyncedBankAccountUrlResponse,
  },
  'BANK_ACCOUNT-addSyncedBankAccounts_onCall': {
    request: AddSyncedBankAccountsOnCallRequest,
    response: SyncedBankAccountUrlResponse,
  },
  'AUTH-createPinForBeneficiary_onCall': {
    request: CreatePinForBeneficiaryOnCallRequest,
    response: ConfirmationResponse,
  },
  'AUTH-loginForBeneficiaryWithPin_onCall': {
    request: LoginForBeneficiaryWithPinOnCallRequest,
    response: LoginResponse,
  },
  'PAYBACKS-getPaybacksMobile_onCall': {
    request: GetPaybacksMobileOnCallRequest,
    response: PaybackModel[],
  },
  'USERS-getFullBeneficiary_onCall': {
    request: GetFullBeneficiaryOnCallRequest,
    response: FullUserModel,
  },
}

@Injectable({
  providedIn: 'root',
})
export class CallerService {
  #functions: Functions = inject(Functions);

  onCall$<FuncName extends keyof BackendClient, Req = BackendClient[FuncName]['request'], Res = BackendClient[FuncName]['response']>(
    functionName: FuncName,
    request: Req,
  ): Observable<Res> {
    return from(
      httpsCallable<Req, OnCallResponseData<Res>>(this.#functions, functionName)(request),
    ).pipe(
      map((result: HttpsCallableResult<OnCallResponseData<Res>>) => deserializeTimestampFields<Res>(result.data)),
    );
  }
}
