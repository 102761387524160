import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AxeptioService {
  loadAxeptio(): void {
    (window as any).axeptioSettings = environment.axeptioSettings

    const script = document.createElement('script');
    script.src = 'https://static.axept.io/sdk.js';
    script.async = true;
    document.head.appendChild(script);
  }
}
